<template>
  <v-list two-line dense class="pt-0">
    <v-list-item 
      class="py-0 blue-grey lighten-5"
      style="min-height:44px !important"
    >
      <v-list-item-content>
        <v-row>
          <v-col 
            cols="12"
            class="d-flex justify-space-between align-center py-0"
          >
            <div>
              Código: <span class="font-weight-bold primary--text"> #{{ vendedor.co_ven }}  </span>        
            </div>
            <div>
              <slot name="action"></slot>
            </div>
          </v-col>
        </v-row>
      </v-list-item-content>
    </v-list-item>
    <v-list-item style="min-height:44px !important" >
      <v-list-item-content>
        <v-list-item-title 
          class="primary--text font-weight-bold display-1"                   
        >
          <v-icon class="mt-n1" color="primary" left>mdi-account</v-icon>
          <span  v-text="vendedor.ven_des" /> 
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>
<script>
export default {
  name:'ListMobile',
  props:{
    vendedor:{
      type: Object,
      default: () => ({}),
    }
  }
}
</script>